.outerDiv {
    display: flex;
    position: relative;
}
.outerDiv:after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    content: '';
    /* background-color: #48a6ea; */
}
.mainDiv {
    /* width:80%;   */
    /* z-index:1; */
    background: transparent;
    position: relative;
    padding-left: 250px;
    flex-direction: column;
}
.mainDiv h3 {
    color: #48a6ea;
    text-align: left;
    padding: 25px 0;
    margin: 0;
    flex: 0 0 100%;
    font-weight: 600;
}
.commnform {
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding-bottom: 48px;
}
.loanForm {
    padding: 10px 60px 30px 60px;
    box-shadow: 0 0 4px 0px #d0cece;
    -webkit-box-shadow: 0 0 4px 0px #d0cece;
    -moz-box-shadow: 0 0 4px 0px #d0cece;
    border-radius: 20px;
    max-width: 100%;
    background-color: #ffffff;
    margin: 0px auto 30px -15px;
}

.loanForm.inactiveVendor {
    padding: 25px 0;
    margin: 0;
    display: flex;
    justify-content: center;
    font-weight: 600;

    color: #48a6ea;

    flex: 0 0 100%;
}
.commnform .form-group {
    flex: 0 0 48%;
    text-align: left;
    margin-bottom: 30px;
    position: relative;
}
.commnform .form-group.nameDiv {
    flex: 0 0 32%;
}
.commnform label {
    margin-bottom: 15px;
}
.form-control {
    border-color: #000000;
    outline: none;
    padding: 9px 15px;
}
.commnform .form-group .error,
.relative .error {
    position: absolute;
    top: 97%;
    left: 0;
    width: 100%;
    color: #ff0800;
    font-size: 14px;
}
.form-control:focus,
.form-control:hover {
    border-color: #48a6ea;
    outline: none;
    box-shadow: none;
}
.commnform .form-group.full {
    flex: 0 0 100%;
    text-align: left;
    margin-bottom: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.form-group.fullline {
    flex: 0 0 100%;
    text-align: left;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}
.form-group.fullline label {
    flex: 0 0 100%;
}
.fullwidth .form-control {
    max-width: 32%;
    margin: 0 0 10px 1%;
}
.commnform .form-group.full .form-control {
    max-width: 17%;
    margin-left: 5%;
}
.commnform .form-group.full button.form-control:hover,
.commnform button.form-group.full button.form-control:focus {
    background: #48a6ea;
    color: #fff;
    border: 1px solid #48a6ea;
}
.termsDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.fullwidth {
    display: flex;
    flex-wrap: wrap;
    max-width: 78%;
    width: 100%;
}
input[type='checkbox'] {
    transform: scale(1.25);
    margin-right: 5px;
}
.termsDiv.mt {
    margin: 25px 25px;
}
.creditbtn .MuiSvgIcon-root {
    font-size: inherit;
}
.creditbtn {
    display: flex;
    align-items: center;
    justify-content: center;
}
.arrBtn {
    background: #48a6ea;
    color: #fff;
    border: 1px solid #48a6ea;
    box-shadow: 0 0 4px 0px #d0cece;
    -webkit-box-shadow: 0 0 4px 0px #d0cece;
    -moz-box-shadow: 0 0 4px 0px #d0cece;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrowBackward svg {
    margin-left: 8px;
}

.fullwidth button:hover,
.fullwidth button:focus {
    background: #48a6ea;
    color: #fff;
    border: 1px solid #48a6ea;
}
.tag {
    background-color: #48a6ea;
    color: #ffffff;
    position: absolute;
    top: 0%;
    bottom: 0;
    right: 0;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-radius: 0.25rem;
}
.fulltag span.tagspan,
.commnform .form-group .fullwidth span.tagspan {
    position: relative;
    max-width: 49%;
    flex: 0 0 48%;
}
.commnform .fulltag {
    flex: 0 0 100%;
    text-align: left;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}
.fulltag .fullwidth .form-control {
    max-width: 100%;
    margin: 0;
}
.fulltag .fullwidth {
    justify-content: flex-end;
    height: min-content;
}

.buttonActive {
    background: #48a6ea !important;
    color: #fff !important;
    border: 1px solid #48a6ea;
}
.relative {
    position: relative;
    width: 100%;
    text-align: left;
}
.commnform .form-group .fullwidth span {
    position: static;
}
.absArrws {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
}
.form-group sup {
    color: #ff0800;
    font-size: inherit;
    top: -0.2em;
}
.form-group.fulltag label {
    margin-bottom: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type='number'] {
    -moz-appearance: textfield;
}
.mainDiv h3.secondH3 {
    font-size: 1.4rem;
    color: #000000;
}
.form-group.oneline {
    display: flex;
    width: 100%;
    flex-grow: 1;
}
.form-group.oneline label {
    white-space: pre;
    margin-right: 30px;
}
.fullwidth.ownrent .form-control.creditbtn {
    max-width: 24%;
}
.form-group.btnM0.fullline .form-control.creditbtn {
    margin-bottom: 0;
}

.inner-addon {
    position: relative;
}

/* style icon */
.inner-addon .glyphicon {
    position: absolute;
    padding: 10px;
    pointer-events: none;
}

/* align icon */
.left-addon .glyphicon {
    left: 0px;
}
.right-addon .glyphicon {
    right: 0px;
}

/* add padding  */
.left-addon input {
    padding-left: 30px;
}
.right-addon input {
    padding-right: 30px;
}

@media (max-width: 1200px) {
    .fullwidth .form-control {
        max-width: 175px;
    }
    .fullwidth {
        max-width: 65%;
    }
}
@media (max-width: 991px) {
    .mainDiv {
        width: 100%;
        padding: 15px;
    }
    .form-group.btnM0.fullline .form-control.creditbtn {
        margin-bottom: 10px;
    }
    .loanForm {
        margin: 0;
        padding: 0px 20px 30px 20px;
    }
    .outerDiv {
        flex-wrap: wrap;
    }
    .fullwidth.ownrent .form-control.creditbtn {
        max-width: 49%;
    }
}
@media (max-width: 768px) {
    .fulltag div,
    .fullline div {
        flex: 0 0 100%;
    }
    .form-group.oneline {
        flex-wrap: wrap;
    }
    .commnform label {
        flex: 0 0 100%;
    }
    .commnform .form-group.full .form-control {
        margin-left: 0;
        margin-right: 2%;
        max-width: 23%;
    }
    .fulltag {
        flex-wrap: wrap;
    }
    .fullwidth {
        max-width: 100%;
    }
    .commnform .form-group.full {
        flex-wrap: wrap;
    }
    .commnform .form-group.full .form-control {
        margin-bottom: 10px;
        min-width: 90px;
    }
}
@media (max-width: 568px) {
    .fulltag span {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .fulltag span:first-child {
        margin-bottom: 10px;
    }
    .commnform .form-group {
        flex: 0 0 100%;
    }
    .commnform .form-group.full .form-control {
        max-width: 100%;
        width: 210px;
        margin: 5px;
    }
    .commnform .form-group.nameDiv {
        flex: 0 0 100%;
    }
    .fulltag span.tagspan,
    .commnform .form-group .fullwidth span.tagspan {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .tag {
        width: 30%;
        justify-content: center;
    }
}
