.commnbg {
    background: #48a6ea;
    min-height: 85vh;
    text-align: center;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}
.loginform form {
    background-color: #fff;
    padding: 30px 30px;
    border-radius: 4px;
}
.mainDiv .loginform h3 {
    padding: 0 0 25px 0;
    text-align: center;
}
.logo {
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo img {
    max-width: 30px;
}
.formwidth {
    width: 90%;
    max-width: 500px;
    padding: 10px 30px;
    margin: 0 auto;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.commnbg h1,
.bgImgDiv h1 {
    font-weight: 600;
    margin-bottom: 40px;
    font-size: 26px;
}
.item-input {
    background: transparent;
    --background: transparent;
    border: 1px solid #fff;
    border-radius: 8px;
    margin-bottom: 20px;
}
.checkdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkboxes {
    margin: 0 6px 0 0;
}
.checkdiv a {
    color: #ffffff;
    text-decoration: none;
}
.item-label {
    --background: transparent;
    background: transparent;
    min-height: auto;
    --min-height: auto;
}
.formwidth .button {
    background: #ffffff;
    --background: #ffffff;
    color: #ca3df3;
    border-radius: 8px;
    margin-top: 0px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 14px 40px !important;
    height: auto;
    border-radius: 18px;
    --padding: 14px 40px !important;
    box-shadow: none !important;
    --box-shadow: none;
    --overflow: initial !important;
}
.haveAcc {
    margin-top: 40px;
}
.formwidth .sc-ion-label-md-h.sc-ion-label-md-s {
    color: #fff;
    margin: 0;
}
.formwidth .item-inner,
.item-native,
.input-wrapper {
    flex-direction: column;
}

.item-input.formItem {
    position: relative;
    overflow: initial;
    margin-bottom: 25px;
}
.formItem p {
    position: absolute;
    left: 0;
    width: 100%;
    color: red;
    bottom: -20px;
    margin: 0;
    font-weight: 500;
    font-size: 14px;
}
.haveAcc a {
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
}
.haveAcc a:hover {
    font-weight: 500;
}
.formwidth .button.arrbtn {
    margin: 0;
    padding: 12px 0 !important;
    position: absolute;
    right: -28px;
}

.inptField {
    /* transition: border-color 0.2s; */
    width: 100%;
    border: none;
    outline: none;
    padding: 8px 15px;
    border: 2px solid #48a6ea;
    border-radius: 8px;
    margin-top: 5px;
}
.form-group label {
    width: 100%;
    color: #48a6ea;
    text-align: left;
    font-weight: 600;
}
.submitBtn {
    background: #48a6ea;
    color: #fff;
    border: 1px solid #48a6ea;
    width: 160px;
    border-radius: 8px;
    font-size: 18px;
    padding: 8px 15px;
    margin-top: 30px;
}
.checkdiv {
    justify-content: right;
}
.checkdiv a {
    color: #48a6ea;
}
.form-group {
    position: relative;

    margin-bottom: 20px;
}
@media (max-width: 568px) {
    .checkdiv {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .checkdiv .item-label {
        --padding-start: 0px;
        display: flex;
        justify-content: flex-start;
    }
    .formwidth {
        padding: 10px;
    }
    .commnbg h1,
    .bgImgDiv h1 {
        font-size: 22px;
        margin: 30px 0 25px 0;
    }
}
