.outerDiv {
    display: flex;
    position: relative;
    min-height: 95vh;
    height: 100%;
    padding-top: 50px;
}
@media screen and (max-width: 1370px), screen and (max-height: 529px) {
    .outerDiv {
        min-height: 95vh;
        height: 100%;
    }
}
.mainDiv {
    width: 100%;
    /* z-index:1; */
    background: transparent;
    position: relative;
    display: flex;
}
.mainAdminDiv {
    padding: 5px 30px 0 30px;
    width: 100%;
}
.mainDiv h3 {
    color: #48a6ea;
    text-align: left;
    padding: 25px 0;
    margin: 0;
    flex: 0 0 100%;
    font-weight: 600;
}
.paginationDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0 0 4px 0px #d0cece;
    -webkit-box-shadow: 0 0 4px 0px #d0cece;
    -moz-box-shadow: 0 0 4px 0px #d0cece;
    border-radius: 4px;
    margin-top: 10px;
    width: max-content;
    float: right;
}
.paginationDiv div {
    padding: 5px 10px;
    border-right: 1px solid #d0cece;
}
.paginationDiv div:last-child {
    border-right: none;
}
.headAdmin {
    display: flex;
    align-items: center;
    max-width: 100%;
}
.headAdmin h3 {
    flex: auto;
}
.headAdmin ul {
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0;
}
.dashbrdPage .MuiTableCell-head {
    font-weight: 600;
}
.headAdmin ul li {
    list-style-type: none;
    font-weight: 600;
}
.headAdmin li .MuiSvgIcon-root {
    margin-right: 10px;
}
.borderDiv {
    max-width: 100%;
    background-color: #ffffff;
    margin: 0px auto 30px auto;
}
.fullwidth {
    display: flex;
    flex-wrap: wrap;
    max-width: 78%;
    width: 100%;
}
.fullwidth button:hover,
.fullwidth button:focus {
    background: #48a6ea;
    color: #fff;
    border: 1px solid #48a6ea;
}
.dashbrdPage table p {
    text-align: center;
}
.btnFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.btnFlex button {
    max-width: 30%;
    width: 200px;
    border-radius: 8px;
    font-size: 22px;
    padding: 12px 10px;
    border: 2px solid #48a6ea;
    color: #888888;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.btnFlex button span.green {
    color: #377e22;
    font-size: 28px;
    font-weight: 600;
}
.btnFlex button span.red {
    color: #ff0000;
    font-size: 28px;
    font-weight: 600;
}
.buttonActive,
.btnFlex button:hover {
    background: #48a6ea !important;
    color: #fff !important;
    border: 2px solid #48a6ea;
}
.relative {
    position: relative;
}
.mainDiv h3.secondH3 {
    font-size: 1.4rem;
    color: #000000;
}
.dashbrdPage {
    padding: 10px 0px;
    box-shadow: 0 0 4px 0px #d0cece;
    -webkit-box-shadow: 0 0 4px 0px #d0cece;
    -moz-box-shadow: 0 0 4px 0px #d0cece;
    border-radius: 8px;
}
.dashbrdPage .tableParent {
    box-shadow: none;
}
.dashbrdPage .tableParent .MuiTableCell-root {
    font-size: 16px;
    padding: 15px 10px;
    text-align: center;
    border: none;
}
.dashbrdPage .table > :not(:last-child) > :last-child > * {
    border: none;
}

@media (max-width: 991px) {
    .mainAdminDiv {
        width: 100%;
    }
    .mainAdminDiv {
        padding: 15px;
    }
    .borderDiv {
        margin: 0;
        padding: 0px 20px 30px 20px;
    }
    .outerDiv {
        flex-wrap: wrap;
    }
    .openside.sidenav {
        position: fixed;
        left: 0;
    }
}
@media (max-width: 768px) {
    .btnFlex button {
        font-size: 18px !important;
    }
    .btnFlex button span.red,
    .btnFlex button span.green {
        font-size: 20px;
    }
    .btnFlex {
        justify-content: space-evenly;
    }
}
@media (max-width: 568px) {
    .paginationDiv {
        float: none;
        margin: 10px auto 0 auto;
    }
    .btnFlex button {
        max-width: 48%;
        width: 200px;
        margin-bottom: 10px;
    }
    .outerDiv {
        padding-top: 100px;
    }
}
