.welcmMain{
    background-color:#48A6EA;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height:88vh;
    letter-spacing:0.2px;
    padding: 0px 20vw;
    position: fixed;
    left: 0;
    right: 0;
}
.welcmMain h1{
    color: #fff;
    font-size: 22px;
    text-align: center;
    font-weight:600;
}
.inWelcome{
    background-color:#fff;
    max-width: 94%;
    width:560px;
    border-radius:8px;
    padding:40px 0;
    box-shadow:0 0 1px 1px #f2f2f2;
}
.inWelcome h2{
    font-size:20px;
}
.inWelcome h5{
    font-size:14px;
}
.inWelcome h4{
    font-size:16px;
}
.yesno{
    display:flex;
    justify-content:center;
    align-items: center;
}
.yesno div{
    border-top:2px solid #48A6EA;
    border-bottom: 2px solid #48A6EA;
    width:50%;
    margin: 25px 0;
}
.yesno label{
    padding:30px 5px;
    display: flex;
    font-size:20px;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
align-items: center;
}
.yesno input{
    margin-bottom:8px;
    transform: scale(1.4);
    cursor: pointer;
}
.yesno div:nth-child(1){
    border-right:2px solid #48A6EA;
}
.youcan{
    padding:0 50px;
    text-align:left;
}
.youcan ul li{
    padding-bottom:5px;
}
.youcan ul li:last-child{
    padding-bottom:0px;
}

@media (max-width:991px){
    .inWelcome{
       max-width: 100%;
        width:500px;
    }
    .welcmMain{
        padding:0 20px;
    }
    .youcan {
        padding: 0 20px;
    }
}