.inSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.inSuccess p {
  font-size: 20px;
}
.successTick {
  max-width: 80px;
  margin-bottom: 10px;
}
.inSuccess h4 {
  color: #008e06;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 25px;
}
.loanForm1 {
  padding: 60px;
  box-shadow: 0 0 4px 0px #d0cece;
  -webkit-box-shadow: 0 0 4px 0px #d0cece;
  -moz-box-shadow: 0 0 4px 0px #d0cece;
  border-radius: 20px;
  max-width: 100%;
  background-color: #ffffff;
}
.mainSuccess {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  /* background: #fff; */
  z-index: 99;
  top: 0;
  bottom: 0;
  margin: auto;
}

@media (max-width: 768px) {
  .loanForm1 {
    padding: 20px 30px;
  }
}
