.sideButtonsNav{
    position:fixed;
    left:215px;
    top:134px;
    bottom:auto;
    z-index:9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:auto;
}
.sideButtonsNav button{
    border:none;
      height:40px;
      width:40px;
      margin:22px 0;
      border-radius: 50%;
      z-index: 11;
      box-shadow: 3px 0px 8px 0px #00000040;
}

@media (max-width:991px){
    .sideButtonsNav{
        display: none;
      }
}