.sidenav.adminSidenav {
    width: 250px;
    max-width: 20%;
    z-index: 1;
    /* background-color: #48A6EA;     */
    padding-top: 40px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    /* position: fixed; 
  left: 0;*/
    height: 100%;
}
.sidenav .disableLink {
    pointer-events: none;
}
.sidenav a {
    padding: 15px 20px;
    text-decoration: none;
    font-size: 18px;
    word-wrap: break-word;
    font-weight: 500;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    transition: ease all 0.5s;
}
.sidenav .navList li a span {
    margin-left: auto;
}
.navList {
    display: block;
    width: 100%;
    padding: 10px 0px;
    text-align: left;
}
.sidenav a:hover {
    color: #ffffff;
}
.siteNameNav {
    font-size: 22px;
    color: #fff;
}
.link-active,
.navList li:hover a {
    background: white;
    color: black !important;
    margin-left: 10px !important;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
}

/* .sidenav a button{
      border:none;
      height:40px;
      width:40px;
      border-radius: 50%;
      z-index: 11;
      position: absolute;
      right:0;
      box-shadow: 3px 0px 8px 0px #00000040;
      
  }
  .sidenav button:hover{
      border:1px solid green;
  }
   */

.sidenav .notopen {
    display: block;
}
.toggleNav {
    display: none;
}
.notopenside .sideInNav {
    background: transparent;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}
@media (max-width: 991px) {
    .notopenside.sidenav .toggleNav.CloseIcon {
        display: none;
    }
    .openside.sidenav .toggleNav.MenuIcon {
        display: none;
    }

    .sidenav .notopen {
        display: none;
    }
    .notopenside.sidenav .toggleNav.CloseIcon {
        display: none;
    }
    .openside.sidenav .toggleNav.MenuIcon {
        display: none;
    }
    .sidenav {
        padding-top: 0;
        width: 100%;
        max-width: max-content;
        background: transparent;
    }
    .openside.sidenav {
        z-index: 1;
        width: 270px;
        max-width: 90%;
    }
    .sidenav .open {
        background-color: #48a6ea;
        padding-top: 20px;
        border-radius: 12px;
        animation: fade-in 2s;
        height: 100%;
    }
    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .sidenav a {
        padding: 10px 20px;
        justify-content: center;
    }
    .sideInNav {
        width: 100%;
        height: 100%;
    }
    .toggleNav {
        display: block;
        background-color: transparent;
        color: #48a6ea;
        position: absolute;
        top: -40px;
        left: 10px;
        box-shadow: none;
        outline: none;
        border: none;
    }
    .sidenav a br {
        display: none;
    }
    .outerDiv:after {
        display: none;
    }
}
