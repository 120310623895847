.footer {
    text-align: center;
    position: relative;
    display: block;
    padding: 8px 15px;
    box-shadow: 0 0 2px 1px #ededed;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.4px;
    color: rgb(29, 29, 29);
    background-color: #fff;
    z-index: 0;
}
.admin-link {
    position: absolute;
    right: 50px;
    /* right: 0px; */
    top: 8px;
    /* bottom: 0px;
    margin: auto; */
}
.admin-link a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    transition: 0.5s ease all;
}
.admin-link:hover a {
    color: black;
}
