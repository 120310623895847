body,
html {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}
.header p {
    margin-bottom: 0px !important;
}
.header {
    text-align: center;
    display: block;
    padding: 8px 15px;
    box-shadow: 0 0 2px 1px #ededed;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.4px;
    background-color: #fff;
    width: 100%;
    z-index: 1;
    position: fixed;
    right: 0;
    left: 0;
}
.title {
    position: absolute;
    left: 20px;
    top: 10px;
    color: #cc0606;
    text-transform: uppercase;
    /* font-size: 16px; */
    margin-bottom: 0px !important;
}
.companyName {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #cc0606;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 0px !important;
}

@media (max-width: 568px) {
    .companyName {
        position: static;
    }
    .title {
        position: static;
    }
}
